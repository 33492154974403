
<template>
    <v-row>
        <v-col cols="12">
            <div>
                <h4>Carrier Appointments</h4>
            </div>
            <div style="width:600px; max-width:90%;">
                <v-spacer></v-spacer>
                <v-text-field v-model="search" label="Search"></v-text-field>
            </div>
            <QExpandableDataTable class="mx-n4" :search=" search" :headers="headers" :visibleHeaders="visibleHeaders"
                                  :items="licensing.appointments" item-key="id" :options.sync="options">

                <template v-slot:item.LoA="{ item }">
                    <!-- probably will need some formatting -->
                    {{ item.LoA }}
                </template>

            </QExpandableDataTable>
        </v-col>
    </v-row>
</template>

<script>

import QExpandableDataTable from '../datatables/QExpandableDataTable.vue'

export default {
    name: "QAgentAppointmentsAgentSync",
    props: ['licensing'], // data passed to component from parent

    components: { QExpandableDataTable },

    data: function() { // data internal to component (not available outside)
        return {
            search: null,

            options: {
                'sortBy': ['EffectiveDate'],
                'sortDesc': [true]
            }
        }
    },

    computed: {
        'headers': function() {
            return [
                { text: 'Company', value: 'Company' },
                { text: 'State', value: 'State' },
                { text: 'LoAs', value: 'LoA' },
                { text: 'Status', value: 'Status' },
                { text: 'Effective Date', value: 'EffectiveDate' },
                { text: 'Renewal Date', value: 'RenewalDate' },
                { text: 'Termination Date', value: 'TerminationDate' },

            ]
        },

        visibleHeaders() {
            let hiddenHeaders = []

            if (this.$vuetify.breakpoint.mdAndDown) {
                hiddenHeaders = ['RenewalDate', 'TerminationDate']
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                hiddenHeaders =  ['RenewalDate', 'TerminationDate', 'EffectiveDate']
            }

            return this.headers.filter((h) => !hiddenHeaders.includes(h.value))
        },

    },
}
</script>
