<template>
    <v-row class="mx-n3">
        <v-col cols="12" v-if="anything_loading">
            <v-progress-linear indeterminate></v-progress-linear>
        </v-col>

        <v-col cols="12" md="6">
            <v-card flat style="height: 100%;">
                <v-card-title>
                    Licensed In The Following States: <v-divider class="mx-5"></v-divider>
                </v-card-title>
                <div class="mx-n4 mx-md-0">
                    <v-list dense>
                        <v-list-group v-for="(lic) in activeLicenses" :key="lic.id">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title label>
                                        <span v-if="lic.IsResidentState">
                                            <v-tooltip top primary>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <b v-bind="attrs" v-on="on"> {{ lic.State}}</b>
                                                </template>
                                                <span>Resident State</span>
                                            </v-tooltip>
                                            - #123456789
                                        </span>
                                        <span v-else>{{ lic.State}} - 123456789</span>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-icon x-small v-if="lic.IsResidentState">fas fa-home</v-icon>
                                </v-list-item-icon>
                            </template>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div>FROM: <b>{{ lic.IssueDate ? formatDate(lic.IssueDate): "" }}</b></div>
                                        <br>
                                        <div>TO: <b>{{ lic.ExpireDate ? formatDate(lic.ExpireDate): "" }}</b></div>
                                        <br>
                                        <div>Lines of Authority: <b>{{ getLOAs(lic.LoAs) }}</b></div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="mt-3 mb-5"></v-divider>
                        </v-list-group>
                    </v-list>
                </div>
            </v-card>
        </v-col>

        <v-col cols="12" md="6" d-flex justify-start mb-6>
            <v-card-title>
                Agent Writing Numbers <v-divider class="mx-5"></v-divider>
            </v-card-title>
            <v-card-subtitle v-if="agent.NPN">
                NPN: {{agent.NPN}}
            </v-card-subtitle>
            <v-card-subtitle v-else>
                <v-alert type="warning">
                    Writing numbers cannot be added until this agent has a valid NPN.
                </v-alert>
            </v-card-subtitle>
            <div class="mx-n4 mx-md-0">
                <q-agent-contracting-staff ref="PersonalContracting" :agent="agent" atype="agent" v-if="hasPermission('manage:Agent')"></q-agent-contracting-staff>
                <q-agent-contracting-agent ref="PersonalContracting" :agent="agent" atype="agent" v-else></q-agent-contracting-agent>
            </div>

            <div v-if="agent.DoingBusinessAsNPN && (hasPermission('manage:Agent') || !agent.HideDBA)">
                <v-expansion-panels accordion v-if="agent.HideDBA" class="mt-4" flat>
                    <v-expansion-panel key="0">
                        <v-expansion-panel-header>DBA is Inactive</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-title>
                                 DBA Writing Numbers<v-divider class="mx-5"></v-divider>
                            </v-card-title>
                            <v-card-subtitle>
                                DBA: {{agent.DoingBusinessAsName}} <br />NPN: 123456789
                            </v-card-subtitle>
                            <div class="mx-n4 mx-md-0">
                                <q-agent-contracting-staff ref="DBAContracting" :agent="agent" atype="agency" v-if="hasPermission('manage:Agent')"></q-agent-contracting-staff>
                                <q-agent-contracting-agent ref="DBAContracting" :agent="agent" atype="agency" v-else></q-agent-contracting-agent>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div v-else>
                    <v-card-title>
                        DBA Writing Numbers <v-divider class="mx-5"></v-divider>
                    </v-card-title>
                    <v-card-subtitle>
                        DBA: {{agent.DoingBusinessAsName}} <br />NPN: 123456789
                    </v-card-subtitle>
                    <div class="mx-n4 mx-md-0">
                        <q-agent-contracting-staff ref="DBAContracting" :agent="agent" atype="agency" v-if="hasPermission('manage:Agent')"></q-agent-contracting-staff>
                        <q-agent-contracting-agent ref="DBAContracting" :agent="agent" atype="agency" v-else></q-agent-contracting-agent>
                    </div>
                </div>
            </div>
        </v-col>

        <!-- Unavailable until AgentSync Contracting API is up and sending this data
        <v-col cols="12" md="3">
            <v-card flat>
                <v-card-title>
                    Additional Info <v-divider class="mx-5"></v-divider>
                </v-card-title>
                <div v-if="licensing.eno" class="mx-n4 mx-md-0">

                </div>
            </v-card>
        </v-col>
        -->

        <v-col cols="12">
            <div v-if="!loading && hasRole(['AgencyOwner', 'SalesRep', 'Staff', 'SuperAdmin'])">
                <q-agent-appointments-agent-sync :licensing="licensing"></q-agent-appointments-agent-sync>
            </div>
        </v-col>

    </v-row>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js';
import QAgentContractingStaff from '@/components/utils/QAgentContractingStaff.vue';
import QAgentContractingAgent from '@/components/utils/QAgentContractingAgent.vue';
import moment from 'moment';
import QAgentAppointmentsAgentSync from "@/components/utils/QAgentAppointmentsAgentSync";

export default {
    name: "QAgentLicensingAgentSync",
    props: ['agent'], // data passed to component from parent

    data: function() { // data internal to component (not available outside)
        return {
            anything_loading: false,
            loading: false,

            licensing: {
                appointments: [],
                licenses: [],
            },
            search: null,

            expanded: [],
            options: {
                'sortBy': ['paperworkDate'],
                'sortDesc': [true]
            }
        }
    },
    mounted: function() {
        this.getLicensing();
    },
    computed: {
        activeLicenses() {
            return  this.licensing.licenses.filter(license => license.Active === true);
        }
    },
    methods: {
        getLicensing() {
            this.loading = true;
            var g = this;
            if (this.agent.NPN == null || this.agent.NPN == "") {
                this.showWarning("This agent needs to add thier NPN number in Opt! in order to view licensing information.")
                g.loading = false;
                return
            }

            QuilityAPI.getAgentLicensing(this.agent, this.role).then(function(resp) {
                g.$set(g, 'licensing', resp.data)
                g.loading = false;
            });
        },


        getLOAs: function(data) {
            return data.map(v => v.Name).join(', ');
        },

        updateAnythingLoading: function() {
            this.anything_loading = this.loading || this.$refs.PersonalContracting.loading
        }
    },
    watch: {
        'loading': function() {
            this.$nextTick(this.updateAnythingLoading)
        },
        '$refs.PersonalContracting.loading': function() {
            this.$nextTick(this.updateAnythingLoading)
        }
    },
    components: {
        QAgentAppointmentsAgentSync,
        QAgentContractingStaff,
        QAgentContractingAgent,
    }
}

</script>

<style scoped>
</style>
