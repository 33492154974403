<template>
    <div v-if="badges.length > 0" class="q-user-badges">
        <v-tooltip bottom v-for="badge in badges" :key="badge.img">
            <template v-slot:activator="{ on, attrs }">
                <v-img v-bind="attrs" v-on="on" :src="badge.img" contain class="q-user-badges__badge" />
            </template>
            <span>{{ badge.tooltip }}</span>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    name: 'UserBadges',

    props: {
        agent: { type: Object, required: true },
    },

    computed: {
        // TODO: not sure what kind of tooltips need to be here

        slingshotBadge() {
            if (['regular', 'golden'].includes(this.agent.SlingshotBadges)) {
                return {
                    img: this.slingshotBadgeUrl(this.agent.SlingshotBadges),
                    tooltip: 'Slingshot Badge'
                }
            }
        },
        summitBadge() {
            if (this.agent.CurrentSummitBadge) {
                return {
                    img: this.agent.CurrentSummitBadge.full,
                    tooltip: 'Summit Badge'
                }
            }
        },

        advisoryBadge() {
            if (!this.agent.AdvisoryBoardMemberDetail) { return }

            if (this.agent.AdvisoryBoardMemberDetail.toLowerCase() === 'legacy') {
                return {
                    img: process.env.VUE_APP_ADVISIORY_BADGE_LEGACY,
                    tooltip: 'Legacy Advisory Board Badge'
                }
            }

            return {
                img: process.env.VUE_APP_ADVISIORY_BADGE,
                tooltip: 'Advisory Board Badge'
            }
        },

        badges() {
            return [this.slingshotBadge, this.summitBadge, this.advisoryBadge].filter(Boolean)
        },
    }
}
</script>
<style lang="scss">
$badge-size: 4rem;
$gap-between-badges: 0.5rem;

.q-user-badges {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    &__badge {
        flex: unset;
        height: $badge-size;
        width: $badge-size;
        margin: 0 $gap-between-badges;
    }
}
</style>