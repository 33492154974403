<template>
    <v-card elevation="0">
        <v-card-subtitle hidden>
            Compliance Corner
        </v-card-subtitle>
        <v-card-text>
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <v-expansion-panels style="position:absolute;" v-if="!loading">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Software Agreement
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table dense v-if="documents.software_agreement.length > 0">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Document</th>
                                        <th class="text-right">Date Signed</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="doc in documents.software_agreement" :key="doc.ID">
                                        <td>{{ doc.ConfigName }}</td>
                                        <td class="text-right">{{ formatReadableDate(doc.CreateDate) }}</td>
                                        <td class="text-center">
                                            <v-btn icon small @click="viewDoc('software-agreement')">
                                                <v-icon>fas fa-eye</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-btn v-else text @click="viewDoc('software-agreement')" color="primary">View Document</v-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>FTC Compliance Agreement</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table dense v-if="documents.ftc.length > 0">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Document</th>
                                        <th class="text-right">Date Signed</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="doc in documents.ftc" :key="doc.ID">
                                        <td>FTC Compliance Agreement</td>
                                        <td class="text-right">{{ formatReadableDate(doc.acknowledgement_date) }}</td>
                                        <td class="text-center">
                                            <v-btn icon small @click="viewDoc('ftc')">
                                                <v-icon>fas fa-eye</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-btn v-else text @click="viewDoc('ftc')" color="primary">View Document</v-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header>QuilityRx Agreement</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table dense v-if="documents.quility_rx.length > 0">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Document</th>
                                        <th class="text-right">Date Signed</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="doc in documents.quility_rx" :key="doc.ID">
                                        <td>QuilityRx Agreement</td>
                                        <td class="text-right">{{ formatReadableDate(doc.CreateDate) }}</td>
                                        <td class="text-center">
                                            <v-btn icon small @click="viewDoc('quility-rx-agreement')">
                                                <v-icon>fas fa-eye</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-btn v-else text @click="viewDoc('quility-rx-agreement')" color="primary">View Document</v-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>

        </v-card-text>

        <v-dialog v-model="showDoc" width="80%" scrollable>
            <v-card style="position:relative" class="q-agent-documents-table__docs-preview">
                <v-btn @click="showDoc = false" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-card-title>{{ showDocTitle }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-progress-linear indeterminate v-if="showDocLoading"></v-progress-linear>
                    <page v-if="showDocSlug != ''" :slug="showDocSlug" :key="showDocSlug"></page>
                    <div v-else class="pt-4" v-html="showDocContent"></div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Page from '@/CMS/views/index.vue'

export default {
    props: ['agent'],
    data() {
        return {
            loading: false,
            showDoc: false,
            showDocLoading: false,
            showDocTitle: "",
            showDocSlug: "",
            showDocContent: "",
            documents: {
                software_agreement: [],
                ftc: [],
                quility_rx: []
            }
        }
    },
    mounted: function() {
        this.loadDocuments()
    },
    methods: {
        loadDocuments: function() {
            this.loading = true
            QuilityAPI.getAgentComplianceDocuments(this.agent)
            .then(json => {
                this.documents = json
                this.loading = false
            })
        },
        viewDoc: function(doc) {
            if (doc == 'software-agreement') {
                this.showDocTitle = "Software Agreement"
                this.showDocSlug = doc
                this.showDoc = true
            } else if (doc == 'quility-rx-agreement') {
                this.showDocTitle = "QuilityRx Agreement"
                this.showDocSlug = doc
                this.showDoc = true
            } else if (doc == 'ftc') {
                this.showDocTitle = "FTC Compliance Agreement"
                this.showDocSlug = ""
                this.showDocLoading = true
                this.showDoc = true
                    
                QuilityAPI.getPopupNotice(142)
                .then(json => {
                    this.showDocTitle = json.data.title
                    this.showDocContent = json.data.content
                    this.showDocLoading = false
                })
            }
            
        }
    },
    components: {
        Page
    }
}
</script>
