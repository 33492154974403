<template>
    <div>
        <v-row>
            <v-col>
                <h2>Documents
                    <v-btn icon @click="updateDocumentList">
                        <v-icon>fas fa-redo-alt</v-icon>
                    </v-btn>
                    <br />
                    <v-btn outlined color="primary" v-if="hasPermission('manage:Agent')" @click="showUpload = !showUpload">Upload</v-btn>
                </h2>
                <q-file-upload v-if="showUpload" upload-url="/api/private/agent_document" :params="{'AgentCode' : agent.AgentCode}" accepted-files="application/vnd.ms-excel, application/csv, application/vnd.ms-word,.csv,.txt,.jpg,.png,.gif,.docx,.doc,.xlsx,.pdf,.msg" upload-name="document" :max-files="null" v-on:complete="uploadComplete"></q-file-upload>
                <v-text-field v-model="search" label="Search" clearable></v-text-field>
            </v-col>
            <v-col>
                <q-compliance-corner :agent="agent.AgentCode"></q-compliance-corner>
            </v-col>
        </v-row>
        
        <QExpandableDataTable :search="search" :headers="the_headers" :visibleHeaders="visibleHeaders" :items="documentList" item-key="ID" class="mx-n4 leaderboard-datatable q-agent-documents-table" :loading="loading" @click:row="previewDocument" :footer-props="{itemsPerPageOptions:[25,50,100]}" :items-per-page.sync="rows">
            <template v-slot:item.ID="{ item }">
                <span class="nowrap">
                    <v-btn color="q_blue_1" small fab icon @click.stop="downloadDoc(item)">
                        <v-icon>fas fa-download</v-icon>
                    </v-btn>
                    <v-btn color="q_agency_1" small fab icon @click.stop="previewDocument(item)">
                        <v-icon>fas fa-eye</v-icon>
                    </v-btn>
                    <v-btn color="q_resources_1" small v-if="hasPermission('manage:Agent')" fab icon @click.stop="editDoc(item)">
                        <v-icon>fas fa-edit</v-icon>
                    </v-btn>
                </span>
            </template>
            <template v-slot:item.DocTitle="{ item }">
                <span>
                    <strong>{{item.DocTitle}}</strong>
                </span>
            </template>
            <template v-slot:item.DocType="{ item }">
                <span>
                    <v-icon color="q_new_b_1" left>{{ getDocIcon(item.DocType) }}</v-icon>
                    {{item.DocType}}
                </span>
            </template>
            <template v-slot:item.Delete="{ item }">
                <span v-if="item.DocType != 'delete'">
                    <v-btn color="red" v-if="hasPermission('manage:Agent')" small fab icon @click.stop="deleteDoc(item)">
                        <v-icon>fas fa-times-circle</v-icon>
                    </v-btn>
                </span>
                <span v-else>
                    <v-progress-circular indeterminate></v-progress-circular>
                </span>
            </template>
        </QExpandableDataTable>

        <v-dialog v-model="showPDFPreview" width="80%">
            <v-card style="position:relative" class="q-agent-documents-table__docs-preview">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <PdfPreview v-if="showPDFPreview" :value="previewDocumentUrl" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDocPreview" width="80%">
            <v-card v-if="previewDocumentDocType == 'image'" style="position:relative">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-img v-if="previewDocumentDocType == 'image'" :src="previewDocumentUrl"></v-img>
            </v-card>
            <v-card v-else style="position:relative" class="q-agent-documents-table__docs-preview">
                <v-btn @click="closePreview" fab color="red" right absolute>
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <VueDocPreview :url="previewDocumentUrl" :type="previewDocumentDocType" />
                <v-loader class="q-agent-documents-table__preview-loader" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="showEditDoc" width="50%">
            <v-card>
                <v-card-title>Edit Document Properties</v-card-title>
                <v-card-text v-if="showEditDoc">
                    <q-file-upload :upload-url="'/api/private/agent_document/' + currentDoc.ID" :params="{}" accepted-files="application/vnd.ms-excel, application/csv, application/vnd.ms-word,.csv,.txt,.jpg,.png,.gif,.docx,.doc,.xlsx,.pdf,.msg" upload-name="document" :max-files="1" v-on:complete="editUploadComplete" text="Drop new version of document here"></q-file-upload>
                    <v-text-field v-model="currentDoc.DocTitle" label="Title"></v-text-field>
                    <v-text-field v-model="currentDoc.DocDesc" label="Description"></v-text-field>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="saveChanges">Save Changes</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QFileUpload from '@/components/utils/QFileUpload.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import PdfPreview from '@/CMS/editor/components/PdfPreview.vue'
import VueDocPreview from 'vue-doc-preview'
import QExpandableDataTable from '../datatables/QExpandableDataTable.vue'
import QComplianceCorner from './QComplianceCorner.vue'

export default {
    props: ['agent'],
    data() {
        return {
            loading: false,
            showUpload: false,
            documentList: [],
            showPDFPreview: false,
            showDocPreview: false,
            previewDocumentDocType: null,
            previewDocumentUrl: null,
            search: null,
            rows: 25,
            showEditDoc: false,
            currentDoc: {}
        }
    },
    mounted: function() {
        this.updateDocumentList();
    },
    computed: {
        the_headers: function() {
            var headers = [{
                text: '',
                value: 'ID',
            }, {
                text: 'Title',
                value: 'DocTitle',
            }, {
                text: 'Date',
                value: 'LastChangeDate'
            }, {
                text: 'Type',
                value: 'DocType',
            }]
            if (this.hasPermission('manage:Agent')) {
                headers.push({
                    text: 'Delete',
                    value: 'Delete',
                    width: "100"
                })
            }
            return headers;
        },
        visibleHeaders() {
            let hiddenHeaders = []

            if (this.$vuetify.breakpoint.mdAndDown) {
                // hiddenHeaders = ['DocType',]
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                hiddenHeaders = ['DocType', 'LastChangeDate']
            }

            if (this.$vuetify.breakpoint.xs) {
                hiddenHeaders = ['ID', 'DocType', 'LastChangeDate', 'Delete']
            }

            return this.the_headers.filter((h) => !hiddenHeaders.includes(h.value))
        },
    },
    methods: {
        uploadComplete: function(resp) {
            this.updateDocumentList()
        },
        editUploadComplete: function(json) {
            var ix = this.indexByKey(this.currentDoc.ID, this.documentList, "ID");
            this.$set(this.documentList, ix, json.data.file);
        },
        updateDocumentList() {
            var g = this
            this.loading = true;
            QuilityAPI.getAgentDocumentList(this.agent.AgentCode, this.role).then(function(json) {
                g.$set(g, 'documentList', json.data)
                g.loading = false;
            }).catch(function(err) {
                g.showError(err.msg)
                console.log(err)
                g.loading = false;
            })
        },
        previewDocument: function(doc) {
            if (doc.DocType == 'pdf') {
                this.showPDFPreview = true;
                this.showDocPreview = false;
            } else {
                this.showPDFPreview = false;
                this.showDocPreview = true;
                switch (doc.DocType) {
                    case 'doc':
                        this.previewDocumentDocType = "office"
                        break;
                    case 'docx':
                        this.previewDocumentDocType = "office"
                        break;
                    case 'txt':
                        this.previewDocumentDocType = "text"
                        break;
                    case 'csv':
                        this.previewDocumentDocType = "code"
                        break;
                    case 'jpg':
                        this.previewDocumentDocType = "image"
                        break;
                    case 'png':
                        this.previewDocumentDocType = "image"
                        break;
                    case 'gif':
                        this.previewDocumentDocType = "image"
                        break;
                    default:
                        this.previewDocumentDocType = "text"
                        break;
                }
            }
            if (process.env.VUE_APP_BASE_URL == 'http://localhost:8080') {
                this.previewDocumentUrl = doc.DownloadLink.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL)
                return
            }
            this.previewDocumentUrl = doc.DownloadLink;
        },
        getDocIcon: function(doctype) {
            switch (doctype) {
                case 'csv':
                    return 'fas fa-file-csv'
                case 'doc':
                    return 'fas fa-file-word'
                case 'xls':
                    return 'fas fa-file-excel'
                case 'xlsx':
                    return 'fas fa-file-excel'
                case 'txt':
                    return 'fas fa-file-text'
                case 'pdf':
                    return 'fas fa-file-pdf'
                case 'docx':
                    return 'fas fa-file-word'
                case 'png':
                    return 'fas fa-file-image'
                case 'jpg':
                    return 'fas fa-file-image'
            }
            return 'fas fa-file'
        },
        downloadDoc: function(doc) {
            if (process.env.VUE_APP_BASE_URL == 'http://localhost:8080') {
                window.open(doc.DownloadLink.replace('http://localhost:8000', process.env.VUE_APP_BASE_URL), "_blank");
                return
            }
            window.open(doc.DownloadLink, "_blank");
        },
        editDoc: function(doc) {
            this.$set(this, 'currentDoc', doc);
            this.showEditDoc = true;
        },
        deleteDoc: function(doc) {
            var g = this
            doc.DocType = "delete"
            QuilityAPI.deleteAgentDocument(doc.ID)
                .then(
                    function(json) {
                        if (json.success === true) {
                            var ix = g.indexByKey(doc.ID, g.documentList, "ID");
                            g.documentList.splice(ix, 1);
                        } else {
                            g.showError("Could not delete this file.")
                        }
                    })
                .catch(
                    function(err) {
                        g.showError(err)
                    })
        },
        saveChanges: function() {
            var g = this
            QuilityAPI.updateAgentDocument(this.currentDoc)
                .then(
                    function(json) {
                        console.log(json)
                        if (json.success === true) {
                            g.showEditDoc = false;
                            var ix = g.indexByKey(g.currentDoc.ID, g.documentList, "ID");
                            g.$set(g.documentList, ix, json.file);
                            //g.$set(g, 'currentDoc', json.file)
                        } else {
                            g.showError("Could not save changes.")
                        }
                    })
                .catch(
                    function(err) {
                        g.showError(err)
                    })
        },
        closePreview: function() {
            this.previewDocumentUrl = ''
            var g = this
            this.$nextTick(function() {
                g.showPDFPreview = false
                g.showDocPreview = false
            })
        }
    },
    watch: {
        'agent.AgentCode': function(newV) {
            var g = this;
            this.$nextTick(function() {
                g.updateDocumentList()
            })
        },
    },
    components: {
        QFileUpload,
        VueDocPreview,
        QExpandableDataTable,
        QComplianceCorner,
        PdfPreview
    }
}
</script>

<style lang="scss" scoped>
.q-agent-documents-table {
    &__docs-preview {
        position: relative;
        z-index: 0;
    }

    &__preview-loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(50%);
        z-index: -1;
    }
}
</style>
